// 通用的登陆方法
export const loginMethods = {
  checkLogin() {
    if (!this.$store.state.user.logged_in) {
      this.$store.commit('user/set_login_redirect', { login_redirect: '/' });
      this.$router.push('/login');
    }
    this.loading = false;
  },
  redirect: function(params) {
    this.$router.push('/' + params);
  },
  logout() {
    this.$store.commit('user/logout');
    this.$router.push('/');
  },
};

// 通用的购物车方法
const myCartKey = 'recosele-cart';
export const cartMethods = {
  getMyCart() {
    const data = localStorage.getItem(myCartKey) || '[]';
    return JSON.parse(data);
  },
  setMyCart(data) {
    console.log(data);
    localStorage.setItem(myCartKey, JSON.stringify(data));
  },
};
