<template>
  <v-bottom-navigation app color="#f57c00">
    <v-btn class="fix-btn-height" to="/spots">
      <span>店舗</span>
      <v-icon>mdi-map-marker-outline</v-icon>
    </v-btn>

    <v-btn class="fix-btn-height" to="/features">
      <span>特集</span>
      <v-icon>mdi-crown</v-icon>
    </v-btn>

    <v-btn class="fix-btn-height" to="/">
      <span>商品</span>
      <v-icon>mdi-home-outline</v-icon>
    </v-btn>

    <v-btn class="fix-btn-height bottom-btn-home" to="/">
      <span style="color: white; font-weight: 900">商品</span>
      <v-icon style="color: white; font-weight: 900">mdi-home-outline</v-icon>
    </v-btn>

    <v-btn class="fix-btn-height" to="/cart">
      <span>カート</span>
      <v-badge color="#f57c00" overlap :content="len" :value="len">
        <v-icon>mdi-cart-outline</v-icon>
      </v-badge>
    </v-btn>

    <v-btn class="fix-btn-height" to="/mypage">
      <span>マイページ</span>
      <v-icon>mdi-account-outline</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
  export default {
    name: 'BottomNavigation',
    computed: {
      len: function () {
        return this.$store.state.cart.cart.length;
      },
    },
  };
</script>

<style>
  .fix-btn-height {
    height: inherit !important;
    min-width: 75px !important;
    width: 75px !important;
    max-width: 75px !important;
    background-color: transparent !important;
  }
  .fix-btn-height::before {
    background-color: transparent !important;
  }
  .bottom-btn-home {
    min-height: 70px !important;
    max-height: 70px !important;
    height: 70px !important;
    min-width: 70px !important;
    max-width: 70px !important;
    width: 70px !important;
    position: absolute !important;
    border: 5px solid white;
    bottom: 0 !important;
    background-color: #f57c00 !important;
    border-radius: 50% !important;
  }
</style>
