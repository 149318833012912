<template>
  <v-container style="max-width: 600px; height: 100%" class="pa-6 pt-10">
    <div style="height: 100%; display: flex; flex-flow: column nowrap">
      <div style="flex: 1 1 auto">
        <v-btn
          v-if="is_corporation"
          x-large
          block
          color="primary"
          @click="redirect('mypage/corporation-order-history')"
        >
          注文履歴
        </v-btn>
        <v-btn v-else x-large block color="primary" @click="redirect('mypage/order-history')">
          購入履歴
        </v-btn>
        <br />
        <v-btn
          v-if="!is_corporation"
          x-large
          block
          color="primary"
          @click="redirect('mypage/return-order-history')"
        >
          返金履歴
        </v-btn>
        <br v-if="!is_corporation" />
        <v-btn x-large block color="primary" @click="redirect('mypage/coupon-list')">
          <div style="white-space: pre-line !important">
            My クーポン
            <!-- <br />
            <span class="caption">クーポンコードの追加もこちらから</span> -->
          </div>
        </v-btn>
        <br />
        <v-btn v-if="!is_corporation" x-large block color="primary" @click="redirect('mypage/point-history')">
          <div style="white-space: pre-line !important">
            My ポイント
          </div>
        </v-btn>
        <br v-if="!is_corporation"/>
        <v-btn
          x-large
          block
          color="primary"
          @click="redirect('mypage/my-coupon-code')"
          class="text-wrap"
        >
          <div style="white-space: pre-line !important">
            友だち招待
            <br />
            <span class="caption">割引クーポン2枚をGETしよう!</span>
          </div>
        </v-btn>
      </div>
      <br />
      <div style="flex: 0 0 auto; padding-bottom: 32px">
        <v-btn large block @click="logout()">log out</v-btn>
        <br />

        <div>
          <div class="text-center">
            <router-link to="/articles/qa" class="caption text-decoration-underline">
              よくある質問
            </router-link>
          </div>
          <!-- <div class="text-center">
            <router-link to="/" class="text-center caption text-decoration-underline">
              利用規約
            </router-link>
          </div> -->
          <div class="text-center">
            <router-link
              to="/articles/privacy"
              class="text-center caption text-decoration-underline"
            >
              個人情報保護方針
            </router-link>
          </div>
          <div class="text-center">
            <router-link to="/articles/rules" class="text-center caption text-decoration-underline">
              特定商取引法表示項目
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <BottomNavigation app></BottomNavigation>
  </v-container>
</template>

<script>
  import BottomNavigation from '@/components/BottomNavigation.vue';
  import { loginMethods } from '@/utils/methods';

  export default {
    name: 'MyPage',
    components: {
      BottomNavigation,
    },
    data: () => ({
      loading: true,
    }),
    computed: {
      is_corporation: function () {
        return this.$store.state.user.is_corporation;
      },
    },
    mounted() {
      // console.log(this.$store.state.user.logged_in);
    },
    methods: {
      ...loginMethods,
    },
  };
</script>
